import React from 'react';

const Index = () => {
  return (
    <>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.491261 7.63586C0.173253 6.77687 -0.0570293 5.8996 0.0124208 4.96751C0.0562841 4.39363 0.271945 3.90383 0.692301 3.50175C1.14921 3.07043 1.58053 2.61717 2.03013 2.17854C2.61497 1.60101 3.34968 1.60466 3.93452 2.17854C4.2964 2.5331 4.65461 2.89497 5.01283 3.25684C5.36008 3.6041 5.71099 3.95135 6.05824 4.30225C6.66867 4.91999 6.67232 5.64008 6.06189 6.25417C5.62326 6.6928 5.18828 7.13509 4.74234 7.56641C4.62537 7.67972 4.6144 7.77476 4.67654 7.91731C4.96897 8.61912 5.39298 9.24418 5.86816 9.82902C6.82584 11.006 7.9078 12.0514 9.19811 12.8592C9.47591 13.031 9.7793 13.159 10.0681 13.3161C10.2179 13.3966 10.3166 13.371 10.4372 13.2467C10.8722 12.7971 11.3182 12.3548 11.7641 11.9125C12.349 11.335 13.08 11.3313 13.6649 11.9125C14.3813 12.6216 15.0941 13.3344 15.8032 14.0509C16.399 14.6503 16.3953 15.385 15.7959 15.9881C15.3901 16.3975 14.9625 16.785 14.5823 17.2127C14.0267 17.8341 13.3249 18.0388 12.5281 17.9949C11.3657 17.9328 10.2947 17.5453 9.26025 17.0445C6.96474 15.9297 5.00552 14.3835 3.3643 12.4316C2.15075 10.9841 1.14921 9.41232 0.491261 7.63586ZM18 8.94444C18 4.01349 13.9865 0 9.05556 0V1.70336C13.0471 1.70336 16.2966 4.95289 16.2966 8.94444H18ZM13.1019 8.94444H14.8053C14.8053 5.77532 12.2247 3.19471 9.05556 3.19471V4.89806C10.1375 4.89806 11.1537 5.31842 11.9176 6.08237C12.6816 6.84632 13.1019 7.86248 13.1019 8.94444Z" fill="#AEB4B9"/>
      </svg>
    </>
  );
};

export default Index;