import React from 'react';

const Index = () => {
  return (
    <>
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0L17 0C17.5523 0 18 0.447715 18 1V1.125L10.0637 6.10938C9.41338 6.51782 8.58662 6.51782 7.93629 6.10938L0 1.125V1ZM0 3.375L7.94 8.3375C8.58854 8.74284 9.41146 8.74284 10.06 8.3375L18 3.375V13C18 13.5523 17.5523 14 17 14H1C0.447715 14 0 13.5523 0 13V3.375Z" fill="#AEB4B9"/>
      </svg>
    </>
  );
};

export default Index;