import React from 'react';

const Index = () => {
  return (
    <>
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 9.19649V15H12.5707V9.58517C12.5707 8.22492 12.0747 7.29661 10.8334 7.29661C9.88579 7.29661 9.32189 7.92222 9.07381 8.5274C8.98334 8.74375 8.96006 9.04488 8.96006 9.34773V15H5.52958C5.52958 15 5.57579 5.82906 5.52958 4.87888H8.95972V6.31351C8.95276 6.32424 8.94372 6.33581 8.93718 6.34621H8.95972V6.31351C9.41545 5.62486 10.2293 4.64111 12.051 4.64111C14.308 4.64107 16 6.08767 16 9.19649ZM1.94116 0C0.767605 0 0 0.755215 0 1.74835C0 2.71984 0.745397 3.49798 1.89563 3.49798H1.91855C3.11484 3.49798 3.85882 2.71999 3.85882 1.74835C3.83628 0.755215 3.11484 0 1.94116 0ZM0.20374 15H3.63291V4.87888H0.20374V15Z" fill="white"/>
      </svg>

    </>
  );
};

export default Index;