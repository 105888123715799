import styles from "./styles.module.scss";
import cn from "classnames";
import Image from "next/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import LangSwitcher from "../../common/components/LangSwitcher";
import Facebook from "../../common/components/Icons/Social/Facebook";
import In from "../../common/components/Icons/Social/In";
import Instagram from "../../common/components/Icons/Social/Instagram";
import Youtube from "../../common/components/Icons/Social/Youtube";
import Phone from "../../common/components/Icons/Common/Phone";
import Mail from "../../common/components/Icons/Common/Mail";
import { ACADEMY_LINK, JOBS_LINK } from "../../../utils/constants";

const contactUsIcons = [<Phone />, <Mail />];

const Footer = () => {
  const { t, lang } = useTranslation("footer");
  const currentYear = new Date().getFullYear();

  const followUs = {
    header: t("followUs.header"),
    list: [
      {
        icon: <Facebook />,
        text: "Facebook",
        href: "https://www.facebook.com/devit.group/",
        alt: "Facebook icon",
        "aria-label": "Facebook",
        external: true,
        width: 10,
        height: 18,
      },
      {
        icon: <In />,
        text: "Linkedin",
        href: "https://www.linkedin.com/company/devit-group/",
        alt: "Linkedin icon",
        "aria-label": "Linkedin",
        external: true,
        width: 16,
        height: 15,
      },
      {
        icon: <Instagram />,
        text: "Instagram",
        href: "https://www.instagram.com/devit.group/",
        alt: "Instagram icon",
        "aria-label": "Instagram",
        external: true,
        width: 18,
        height: 18,
      },
      {
        icon: <Youtube />,
        text: "Youtube",
        href: "https://www.youtube.com/c/DevIT-group",
        alt: "Youtube icon",
        "aria-label": "Youtube",
        external: true,
        width: 18,
        height: 12,
      },
    ],
  };

  const company = {
    header: t("company.header"),
    list: [
      {
        href: "https://devit.group/",
        text: t("company._1.text"),
        external: true,
      },
      {
        href: ACADEMY_LINK,
        text: t("company._2.text"),
        external: true,
      },
      {
        href: JOBS_LINK,
        text: t("company._3.text"),
        external: true,
      },
      {
        href: "/training-center",
        text: t("company._4.text"),
        external: false,
      },
      {
        href: "/about-us",
        text: t("company._5.text"),
        external: false,
      },
    ],
  };

  const support = {
    header: t("support.header"),
    list: [
      {
        href: `https://status.devit.software/`,
        text: t("support._1.text"),
        external: true,
      },
      {
        href: "/hire",
        text: t("support._2.text"),
        external: false,
      },
      {
        href: `https://help.devit.software/`,
        text: t("support._3.text"),
        external: true,
      },
      {
        href: "/customer-support",
        text: t("support._4.text"),
        external: false,
      },
      {
        href: "/contact-us",
        text: t("support._5.text"),
        external: false,
      },
    ],
  };

  const contactUs = {
    header: t("contactUs.header"),
    list: [
      {
        icon: <Phone />,
        alt: "Phone icon",
        text: "+1 (929) 237-1255",
        href: "tel:+19292371255",
        width: 18,
        height: 18,
      },
      {
        icon: <Mail />,
        alt: "Mail icon",
        text: "support@devit.software",
        href: "mailto:support@devit.software",
        width: 18,
        height: 14,
      },
    ],
    links: [
      {
        text: t("contactUs.privacy.text"),
        href: "/privacy",
      },
      {
        text: t("contactUs.term.text"),
        href: "/terms-of-use",
      },
      {
        text: t("contactUs.cookie.text"),
        href: "/cookie-policy",
      },
      {
        text: t("contactUs.affiliate.text"),
        href: "/affiliate-terms",
      },
    ],
  };

  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer__container"]}>
        <div className={styles["footer__left"]}>
          <div className={styles["footer__left-container"]}>
            <section
              className={cn(
                styles["footer__company"],
                styles["footer-block"],
                styles["company"]
              )}
            >
              <h5 className={styles["footer-block__header"]}>
                {company.header}
              </h5>
              <ul className={styles["footer-block__list"]}>
                {company.list.map((link, i) => (
                  <li key={i} className={styles["footer-block__item"]}>
                    <Link href={link.href}>
                      <a
                        className={cn(
                          styles["footer-block__link"],
                          { [styles["link-external"]]: link.external },
                          styles["footer-link"]
                        )}
                        target={link.external ? "_blank" : "_self"}
                        rel="noopener noreferrer"
                      >
                        <span>{link.text}</span>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
            <section
              className={cn(
                styles["footer__support"],
                styles["footer-block"],
                styles["support"]
              )}
            >
              <h5 className={styles["footer-block__header"]}>
                {support.header}
              </h5>
              <ul className={styles["footer-block__list"]}>
                {support.list.map((link, i) => (
                  <li key={i} className={styles["footer-block__item"]}>
                    <Link href={link.href}>
                      <a
                        className={cn(
                          styles["footer-block__link"],
                          { [styles["link-external"]]: link.external },
                          styles["footer-link"]
                        )}
                        target={link.external ? "_blank" : "_self"}
                        rel="noopener noreferrer"
                      >
                        <span>{link.text}</span>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
            <section
              className={cn(
                styles["footer__follow-us"],
                styles["footer-block"]
              )}
            >
              <h5 className={styles["footer-block__header"]}>
                {followUs.header}
              </h5>
              <ul className={styles["footer-block__list"]}>
                {followUs.list.map((link, i) => (
                  <li key={i} className={styles["footer-block__item"]}>
                    <Link href={link.href}>
                      <a
                        target={link.external ? "_blank" : "_self"}
                        className={cn(
                          styles["footer-block__link"],
                          styles["footer-link"]
                        )}
                        rel="noopener noreferrer"
                        aria-label={link["aria-label"]}
                      >
                        <span className={styles["footer-block__icon-wrapper"]}>
                          {link.icon}
                        </span>
                        <span className={styles["footer-block__link-text"]}>
                          {link.text}
                        </span>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          </div>
          <span className={styles["footer__copyright"]}>
            {t("copyright.beforeYear")}
            &nbsp;
            {currentYear}
            &nbsp;
            {t("copyright.afterYear")}
          </span>
        </div>
        <div className={styles["footer__right"]}>
          <section className={styles["contact-us"]}>
            <h5 className={styles["footer-block__header"]}>
              {contactUs.header}
            </h5>
            <ul
              className={cn(
                styles["contact-us__list"],
                styles["footer-block__list"]
              )}
            >
              {contactUs.list.map((link, i) => (
                <li key={i} className={styles["contact-us__item"]}>
                  <Link href={link.href}>
                    <a
                      className={cn(
                        styles["contact-us__link"],
                        styles["footer-link"]
                      )}
                    >
                      {link.icon}
                      <span className={styles["contact-us__link-text"]}>
                        {link.text}
                      </span>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <div className={styles["switcher__wrapper"]}>
              <LangSwitcher />
            </div>
            <div className={styles["info-links"]}>
              <ul className={styles["info-links__list"]}>
                {contactUs.links.map((link, i) => (
                  <li key={i} className={styles["info-links__item"]}>
                    <Link href={link.href}>
                      <a
                        className={cn(
                          styles["info-links__link"],
                          styles["footer-link"]
                        )}
                      >
                        {link.text}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
