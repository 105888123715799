import React from 'react';

const Index = () => {
  return (
    <>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.00002 0.5C11.3085 0.5 11.5979 0.509785 12.5045 0.551151C13.4093 0.592416 14.0272 0.736118 14.5678 0.946256C15.1268 1.16345 15.6008 1.45409 16.0734 1.92663C16.5459 2.3992 16.8366 2.87323 17.0538 3.43218C17.2639 3.97284 17.4076 4.59073 17.4488 5.49546C17.4902 6.40208 17.5 6.69154 17.5 9.00002C17.5 11.3085 17.4902 11.5979 17.4488 12.5045C17.4076 13.4093 17.2639 14.0272 17.0538 14.5678C16.8366 15.1268 16.5459 15.6008 16.0734 16.0734C15.6008 16.5459 15.1268 16.8366 14.5678 17.0538C14.0272 17.2639 13.4093 17.4076 12.5045 17.4488C11.5979 17.4902 11.3085 17.5 9.00002 17.5C6.69154 17.5 6.40208 17.4902 5.49546 17.4488C4.59073 17.4076 3.97284 17.2639 3.43218 17.0538C2.87323 16.8366 2.3992 16.5459 1.92663 16.0734C1.45409 15.6008 1.16345 15.1268 0.946256 14.5678C0.736118 14.0272 0.592416 13.4093 0.551151 12.5045C0.509785 11.5979 0.5 11.3085 0.5 9.00002C0.5 6.69154 0.509785 6.40208 0.551151 5.49546C0.592416 4.59073 0.736118 3.97284 0.946256 3.43218C1.16345 2.87323 1.45409 2.3992 1.92663 1.92663C2.3992 1.45409 2.87323 1.16345 3.43218 0.946256C3.97284 0.736118 4.59073 0.592416 5.49546 0.551151C6.40208 0.509785 6.69154 0.5 9.00002 0.5ZM8.99734 11.8298C10.5616 11.8298 11.8298 10.5616 11.8298 8.99734C11.8298 7.433 10.5616 6.16488 8.99734 6.16488C7.433 6.16488 6.16488 7.433 6.16488 8.99734C6.16488 10.5616 7.433 11.8298 8.99734 11.8298ZM8.99734 4.63383C11.4072 4.63383 13.3608 6.58742 13.3608 8.99734C13.3608 11.4072 11.4072 13.3608 8.99734 13.3608C6.58742 13.3608 4.63383 11.4072 4.63383 8.99734C4.63383 6.58742 6.58742 4.63383 8.99734 4.63383ZM14.8556 4.37224C14.8556 4.94601 14.3904 5.41111 13.8166 5.41111C13.2429 5.41111 12.7778 4.94601 12.7778 4.37224C12.7778 3.79847 13.2429 3.33333 13.8166 3.33333C14.3904 3.33333 14.8556 3.79847 14.8556 4.37224Z" fill="white"/>
      </svg>
    </>
  );
};

export default Index;