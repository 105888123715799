import React from 'react';

const Index = () => {
  return (
    <>
      <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.1985 1.29967C18.4824 1.59231 18.684 1.95467 18.783 2.35016C19.0474 3.81574 19.1757 5.30262 19.1663 6.79183C19.1716 8.25877 19.0433 9.72315 18.783 11.1668C18.684 11.5623 18.4824 11.9247 18.1985 12.2173C17.9147 12.51 17.5586 12.7225 17.1663 12.8335C15.733 13.2168 9.99966 13.2168 9.99966 13.2168C9.99966 13.2168 4.26633 13.2168 2.833 12.8335C2.44868 12.7283 2.09798 12.5258 1.81478 12.2456C1.53158 11.9653 1.32546 11.6167 1.21633 11.2335C0.951969 9.76791 0.823645 8.28103 0.832996 6.79183C0.825693 5.31375 0.954008 3.83813 1.21633 2.3835C1.31532 1.988 1.51693 1.62564 1.80078 1.333C2.08464 1.04036 2.4407 0.827817 2.833 0.716829C4.26633 0.333496 9.99966 0.333496 9.99966 0.333496C9.99966 0.333496 15.733 0.333496 17.1663 0.683496C17.5586 0.794483 17.9147 1.00703 18.1985 1.29967ZM12.9167 6.79184L8.125 9.51684V4.06684L12.9167 6.79184Z" fill="white"/>
      </svg>
    </>
  );
};

export default Index;